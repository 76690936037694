/**
 * @file Simplified Portal Component
 * @author Alwyn Tan
 */

import React, { useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type PortalProps = {
  children: React.ReactNode
  wrapperID?: string
}

const createWrapperAndAppendToBody = (wrapperID: string) => {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperID)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

const Portal = ({
  children,
  wrapperID = 'root-portal-wrapper',
}: PortalProps) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperID)
    if (!element) element = createWrapperAndAppendToBody(wrapperID)

    setWrapperElement(element)
  }, [wrapperID])

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null

  return createPortal(children, wrapperElement)
}

export default Portal
