/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const postRequestLogin = async (phoneNumber: string) =>
  (
    await axios.post(
      '/user/request-login',
      { phoneNumber },
      { withCredentials: true }
    )
  ).data

const useRequestLogin = () =>
  useMutation(async (phoneNumber: string) => postRequestLogin(phoneNumber))

export default useRequestLogin
