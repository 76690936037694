/**
 * @file Bottom Sheet Backdrop used in bottom sheets
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
`

type Props = {
  onClick: () => void
}

const BottomSheetBackdrop = ({ onClick }: Props) => (
  <Container
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
    onClick={onClick}
  />
)

export default BottomSheetBackdrop
