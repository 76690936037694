/**
 * @file Theme colors
 * @author Alwyn Tan
 */

import { DefaultTheme } from 'styled-components'
import { OffBlack, Purple, Red } from '#/constants/colors'

declare module 'styled-components' {
  export interface DefaultTheme {
    Background: string
    Text: string
    Error: string
    PrimaryColor: string
    MaxContainerWidth: string
  }
}

const theme: DefaultTheme = {
  Background: OffBlack,
  Text: 'white',
  Error: Red,
  PrimaryColor: Purple,
  MaxContainerWidth: '900px',
}

export default theme
